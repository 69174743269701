.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pe-bg-1 {
  background: url('./assets/images/bg-1.png');
  background-size: cover;
  background-position: center center;
}
.pe-bg-4 {
  background: url('./assets/images/bg-4.png');
  background-size: cover;
  background-position: center center;
}
.pe-gradient-1 {
  background: linear-gradient(180deg, rgb(255 255 255) 0%, rgb(128 255 222 / 0%) 25%, rgb(255 255 255 / 0%) 80%, rgb(255 255 255) 100%, rgb(255 255 255) 100%)
}

.pe-bg-primary {
  background: #003a70;
}

.pe-bg-secondary {
  background: #00d496;
}

.pe-bg-third {
  background: #000000;
}


.pe-text-primary {
  color: #003a70;
}

.pe-text-secondary {
  color: #00d496;
}

.pe-text-third {
  color: #000000;
}

.checker {
  fill: rgb(141 172 195 / 50%);
}
.checker2 {
  fill: rgb(5 212 149 / 50%);
}

.pe-controls-player svg:hover{
  cursor: pointer;
  fill: #004688;
  filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 0.4));
}

.pe-team {
  animation: MoveUpDown 3s ease infinite;
}
.pe-team g {
  transition: transform 0.5s, filter 1s ease-in-out;
  transform-origin: center center;
  position: relative;
}
.pe-team g:hover {
  transform: scale(1.02);
}


@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(1px);
  }
  50% {
    transform: translateY(5px);
  }
}